import React, { Component } from 'react';
import { connect } from 'react-redux';

import { oauth, toTitleCase, makeCancelable, parseMysqlDate } from '../utils';

import { createAddTemp } from '../actions/temp';
import { closePopup, createCreateProjectAndOrderPopup } from '../actions/popup';
import { createCopyItemFromCollection } from '../actions/index';

import { BASE_ZINDEX } from '../popup-factory';

const ORDER_TYPES = [
  'PRESENTATION',
  'ESTIMATE',
  'SALES ORDER'
];

const ORDER_TYPE_PATHS = {
  'PRESENTATION': 'presentation',
  'ESTIMATE': 'estimate',
  'SALES ORDER': 'sales-order'
};

const PAGE_SIZE = 25;

class CopyToProjectPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      loading: false,
      projects: props.projects || [],
      shops: props.shops || [],
      page: 0,
      search_done: false,
      tab: 'project'
    };

    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  UNSAFE_componentWillMount() {
    if ('SUPPLIER' !== this.props.identity.company_type && !this.state.projects.length) {
      this.onSearch(0);
    }
  }

  componentWillUnmount() {
    if (this.entities) {
      this.entities.removeEventListener('scroll', this.handleScroll);
    }
    if (this.request) {
      this.request.cancel();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projects && nextProps.projects !== this.props.projects) {
      const order = nextProps.projects.length ? nextProps.projects[0].orders[0] : null;
      this.setState(
        { projects: nextProps.projects },
        () => order && nextProps.onCopyToOrder(order.order_id, order.order_type)
      );
    }
    if (nextProps.shops && nextProps.shops !== this.props.shops) {
      const shop = nextProps.shops.length ? nextProps.shops[0] : null;
      this.setState(
        { shops: nextProps.shops },
        () => shop && nextProps.onCopyToOrder(shop.order_id, 'SHOP')
      );
    }
  }

  handleChangeSearch(e) {
    this.setState({ search: e.target.value }, () => this.onSearch(0));
  }

  handleChangeTab(tab) {
    this.setState({ tab }, () => this.onSearch(0));
  }

  handleScroll(e) {
    if (this.entities.scrollHeight - this.entities.scrollTop === this.entities.clientHeight) {
      this.entities.removeEventListener('scroll', this.handleScroll);
      if (!this.state.search_done) {
        this.onSearch(this.state.page + 1);
      }
    }
  }

  onSearch(page) {
    if (this.request) {
      this.request.cancel();
    }
    let route = 'shop';
    let entity = 'shops';
    if (this.state.tab === 'project') {
      route = 'project';
      entity = 'projects';
    }
    this.setState({ loading: true, page, [entity]: 0 === page ? [] : this.state[entity] });
    let obj = {
      search: this.state.search,
      'max-results': PAGE_SIZE,
      'search-index': page * PAGE_SIZE
    };
    if (this.state.tab === 'shop') {
      obj.order_by = 'date_created';
      obj.order_dir = 'DESC';
      obj.with_shop_order = true;
    }
    if (this.state.tab === 'project') {
      obj.locked = 0;
    }
    const request = makeCancelable(oauth('GET', route, obj));
    this.request = request;
    request.promise.then(
      ({ json }) => {
        this.setState({
          loading: false,
          [entity]: 0 === page ? json[entity] : this.state[entity].concat(json[entity]),
          search_done: json[entity].length < PAGE_SIZE
        });
        if (json[entity].length >= PAGE_SIZE) {
          this.entities.addEventListener('scroll', this.handleScroll);
        }
      },
      ({ json }) => this.setState({ loading: false, [entity]: [], search_done: true })
    );
  }

  renderShop(shop) {
    return (
      <div key={shop.shop_id} className="row collapse" style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}>
        <div className="small-12 medium-3 large-2 columns">
          <a href={`/shop/${shop.shop_number}`} target="_blank">#{shop.shop_number} - {shop.shop_name}</a>
        </div>
        <div className="small-12 medium-3 columns">
          <p style={{ marginBottom: '0.25rem' }}>{shop.client_name}</p>
          <p className="show-for-small-only" style={{ fontSize: '0.8em', marginBottom: 0 }}>{shop.contact_first_name} {shop.contact_last_name}</p>
          <p className="show-for-small-only" style={{ fontSize: '0.8em' }}>{parseMysqlDate(shop.date_created)}</p>
        </div>
        <div className="hide-for-small-only medium-2 columns">
          <p style={{ fontSize: '0.8em', marginBottom: 0 }}>{shop.contact_first_name} {shop.contact_last_name}</p>
        </div>
        <div className="hide-for-small-only medium-2 columns">
          <p style={{ fontSize: '0.8em' }}>{parseMysqlDate(shop.date_created)}</p>
        </div>
        <div className="small-12 medium-2 columns">
          {this.renderShopButton(shop)}
        </div>
      </div>
    );
  }

  renderShopButton(shop) {
    const { onCopyToOrder, addedToOrders } = this.props;

    if ('loading' === addedToOrders[shop.order_id]) {
      return (
        <button key={shop.order_id} className="button expanded hollow" style={{ marginBottom: '0.5rem', padding: '0.25rem' }}>
          <img style={{ maxHeight: '2rem' }} src="/images/gears.gif" />
        </button>
      );
    } else if ('added' === addedToOrders[shop.order_id]) {
      return (
        <a key={shop.order_id} className="button expanded hollow" style={{ marginBottom: '0.5rem' }} href={`/shop/${shop.shop_number}`} target="_blank">
          <i className="fi-check" style={{ float: 'left' }}></i>View Shop
        </a>
      );
    } else {
      return (
        <button key={shop.shop_id} className="button expanded" style={{ marginBottom: '0.5rem' }} onClick={e => onCopyToOrder(shop.order_id, 'SHOP')}>
          Add Products
        </button>
      );
    }
  }

  renderProject(project) {
    const { onCopyToOrder, addedToOrders } = this.props;

    return (
      <div key={project.job_id} className="row collapse" style={{ borderBottom: '1px solid lightgray', marginBottom: '1rem' }}>
        <div className="small-12 medium-3 large-2 columns" style={{ fontSize: '2em', textAlign: 'center' }}>
          #{project.job_number}
        </div>
        <div className="small-12 medium-6 large-7 columns">
          <p style={{ marginBottom: '0.25rem' }}><a href={`/project/${project.job_number}`} target="_blank">{project.job_name}</a></p>
          <p style={{ fontSize: '0.8em', marginBottom: 0 }}>{project.client_name}</p>
          <p style={{ fontSize: '0.8em' }}>Created on {project.date_created}</p>
        </div>
        <div className="small-12 medium-3 columns">
          {project.orders.filter(o => ORDER_TYPES.includes(o.order_type)).map(o => {
            if ('loading' === addedToOrders[o.order_id]) {
              return (
                <button key={o.order_id} className="button expanded hollow" style={{ marginBottom: '0.5rem', padding: '0.25rem' }}>
                  <img style={{ maxHeight: '2rem' }} src="/images/gears.gif" />
                </button>
              );
            } else if ('added' === addedToOrders[o.order_id]) {
              return (
                <a key={o.order_id} className="button expanded hollow" style={{ marginBottom: '0.5rem' }} href={`/project/${project.job_number}/${ORDER_TYPE_PATHS[o.order_type]}/${o.form_number}`} target="_blank">
                  <i className="fi-check" style={{ float: 'left' }}></i>{toTitleCase(o.order_type)} #{o.form_number}
                </a>
              );
            } else {
              return (
                <button key={o.order_id} className="button expanded" style={{ marginBottom: '0.5rem' }} onClick={e => onCopyToOrder(o.order_id, o.order_type)}>
                  Add to {toTitleCase(o.order_type)}
                </button>
              );
            }
          })}
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="row small-12 columns collapse" style={{ textAlign: 'center' }}>
        <img src="/images/gears.gif" />
      </div>
    );
  }

  renderSupplierView() {
    const { index, totalCartItems, onClosePopup } = this.props;

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'auto' }} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row small-12 columns">
          <h3>Add {totalCartItems} item{totalCartItems > 1 ? 's' : ''} to project</h3>
        </div>
        <div className="row small-12 columns">
          <p>Here the distributor can choose the projects to which they want to add the selected products.</p>
        </div>
        <a className="button hide-for-small-only" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={handleCancel}>Close</a>
        <a className="close-button show-for-small-only" onClick={handleCancel}>&times;</a>
      </div>
    );
  }

  render() {
    const { items, index, totalCartItems, onCreateProject, onCreateShop, onClosePopup, identity } = this.props;
    const { search, loading, projects, tab, shops } = this.state;

    if ('SUPPLIER' === identity.company_type) {
      return this.renderSupplierView();
    }

    const handleCreateProject = e => {
      e.preventDefault();
      onCreateProject();
    };

    const handleCreateShop = e => {
      e.preventDefault();
      onCreateShop();
    };

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX }} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row small-12" style={{ marginBottom: '10px' }}>
          <h3>Add {totalCartItems} item{totalCartItems > 1 ? 's' : ''}</h3>
          <div className="row">
            <div className="small-12 medium-4 columns" style={{ marginBottom: '10px', padding: 0 }}>
              <ul id="order-tabs" className="tabs" data-tabs style={{ border: 'none' }}>
                <li className={'tabs-title' + (tab === 'shop' ? ' is-active' : '')} style={{ background: 'white' }}>
                  <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeTab('shop'); }} aria-selected={tab === 'shop'}>Shops</a>
                </li>
                <li className={'tabs-title' + (tab === 'project' ? ' is-active' : '')}>
                  <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeTab('project'); }} aria-selected={tab === 'project'}>Projects</a>
                </li>
              </ul>
            </div>
            <div className="small-12 medium-5 columns">
              <input type="text" value={search} onChange={this.handleChangeSearch} placeholder="Search by name, number or client" />
            </div>
            <div className="medium-3 columns" style={{ padding: 0 }}>
              {tab === 'project' ? <a className="button hide-for-small-only" style={{ float: 'right' }} onClick={handleCreateProject}>Create Project</a> : null}
              {tab === 'shop' ? <a className="button hide-for-small-only" style={{ float: 'right' }} onClick={handleCreateShop}>Create Shop</a> : null}
            </div>
          </div>
        </div>
        <div ref={ref => { this.entities = ref; }} className="row small-12 columns" style={{ overflowY: 'auto', height: '80%' }}>
            {tab === 'project' ?
              projects.filter(p => p.orders.filter(o => ORDER_TYPES.includes(o.order_type)).length).map(p => this.renderProject(p))
            :
              shops.filter(s => s.status_name !== 'Closed').map(s => this.renderShop(s))
            }
            {loading ?  this.renderLoading() : null}
        </div>
        <a className="button hide-for-small-only" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={handleCancel}>Cancel</a>
        <a className="close-button show-for-small-only" onClick={handleCancel}>&times;</a>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  totalCartItems: Object.values(state.temp).filter(i => i.total_quantity).length,
  addedToOrders: state.added_to_orders,
  identity: state.identity
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreateProject: () => dispatch(createCreateProjectAndOrderPopup()),
  onCreateShop: () => {
    dispatch(createAddTemp('create_shop', 'no_redirect'));
  },
  onClosePopup: () => dispatch(closePopup()),
  onCopyToOrder: (order_id, order_type) => {
    const item_ids = ownProps.items.map(i => i.item_id);
    return dispatch(createCopyItemFromCollection(ownProps.collection_id, item_ids, order_id));
  }
});

const ConnectedCopyToProjectPopup = connect(mapStateToProps, mapDispatchToProps)(CopyToProjectPopup);
export default ConnectedCopyToProjectPopup;
